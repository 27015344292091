.main-about .intro {
    box-sizing: border-box;
    width: 100%;
    padding: 40px 20px;
    background-image: url('./../../asset/images/background_home.png');
    background-size: cover;
    background-position: center center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.main-about .intro h1 {
    color: white;
    font-family: 'Oxanium', cursive;
    font-size: 3rem;
    letter-spacing: 0.4rem;
    text-align: center;
}

.main-about .intro p {
    margin: 28px 0;
    color: #F0F0F0;
    font-family: 'Noto Sans KR', sans-serif;
    font-size: 1.5rem;
    line-height: 1.6;
    text-align: center;
}

.main-about .members {
    box-sizing: border-box;
    width: 100%;
    padding: 40px 20px;
    background-color: #f6f6f6;
    display: flex;
    align-items: center;
    justify-content: center;
}

.main-about .members > div {
    text-align: center;
}

.main-about .members h1 {
    color: black;
    font-family: 'Noto Sans KR', sans-serif;
    font-size: 3rem;
    letter-spacing: 0.4rem;
    text-align: center;
}

.main-about .members .profile {
    display: inline-block;
    height: 200px;
    margin: 20px 40px;
}

.main-about .members .profile.sub {
    color: #BFBFBF;
}

.main-about .members .profile .img {
    display: inline-block;
    width: 150px;
    margin: 0 20px;
    vertical-align: bottom;
}

.main-about .members .profile .img img {
    width: 100%;
}

.main-about .members .profile .description {
    box-sizing: border-box;
    width: 320px;
    display: inline-block;
    margin: 0 20px;
    font-family: 'Noto Sans KR', sans-serif;
    text-align: left;
    vertical-align: bottom;
}

.main-about .members .profile .description.right {
    text-align: right;
}

.main-about .members .profile .description .name {
    font-size: 2.5rem;
    letter-spacing: 0.75rem;
}

.main-about .members .profile .description.right .name {
    margin-right: -1.2rem;
}

.main-about .members .profile .description .responsibility {
    font-size: 1.5rem;
    letter-spacing: 0.15rem;
}

.main-about .members .profile .description.right .responsibility {
    margin-right: -0.15rem;
}

.main-about .members .profile .description ul {
    padding: 0;
    list-style: none;
}

.main-about .members h2 {
    color: black;
    font-family: 'Inter', sans-serif;
    font-size: 2rem;
    letter-spacing: 0.2rem;
    text-align: center;
    margin-top: 50px;
}

.main-about .members .link {
    display: inline-block;
    font-family: 'Inter', sans-serif;
    font-size: 1.3rem;
    color: black;
    text-decoration: none;
    padding: 10px 22px 13px 22px;
    border: 2px solid #D32430;
    border-radius: 3px;
    transition-duration: 150ms;
    margin: 50px 0;
}

.main-about .members .link:hover {
    color: white;
    background-color: #D32430;
}


@media screen and (max-width: 859px) {

    .main-about .members .profile .description { 
        width: 100%;
    }

}