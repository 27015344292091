.app-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 64px;
    overflow: hidden;
    background-color: white;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
    z-index: 9000;
}

.header-logo {
    position: absolute;
    top: 0;
    left: 12px;
    cursor: pointer;
}

.header-logo img {
    padding: 10px;
    height: 44px;
}

.header-links {
    position: absolute;
    top: 0;
    right: 12px;
}

.header-links .link {
    box-sizing: border-box;
    display: inline-block;
    padding: 3px 24px;
    line-height: calc(64px - 6px);
    text-align: center;
    font-family: 'Inter', sans-serif;
    color: black;
    font-size: 16px;
}

.header-links .link:hover {
    transition-duration: 150ms;
    color: #D32430;
}

.header-links .active .link {
    padding-bottom: 0;
    border-bottom: 3px solid #D32430;
}

@media screen and (max-width: 859px) {
    .app-header .bm-burger-button {
        width: 44px;
        height: 44px;
        margin: 10px;
        background-image: url('../asset/images/hamburger_icon.svg.png');
        background-size: contain;
        opacity: 0.7;
    }
    
    .app-header .bm-overlay {
        width: 180px !important;
        background: rgba(0, 0, 0, 0.7) !important;
        top: 64px !important;
        right: 0 !important;
        color: white !important;
    }
    
    .app-header .bm-menu-wrap {
        width: 180px !important;
        top: 64px !important;
        right: 0 !important;
        color: white !important;
    }

    .app-header .bm-item-list {
        height: calc(100% - 64px) !important;
        padding-top: 12px !important;
    }

    .header-links .bm-item-list a.bm-item {
        outline: none
    }

    .header-links .link {
        color: white;
        padding: 0 20px;
    }

    .header-links .active .link {
        border-bottom: none;
    }

    .header-links .link:hover {
        color: #e2535d;
    }
}

