.products-gcgcn-online {
    box-sizing: border-box;
    width: 100%;
    background-color: #f6f6f6;
    text-align: center;
}

.products-gcgcn-online h1 {
    margin: 0;
    padding: 50px 0;
    font-family: 'Raleway', sans-serif;
    font-size: 3rem;
}

.products-gcgcn-online .search {
    display: inline-block;
    position: relative;
    width: calc(100% - 40px);
    margin: 0 20px;
    max-width: 600px;
    height: 52px;
    border: 2px solid #D32430;
    border-radius: 2px;
}

.products-gcgcn-online .search input {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    font-family: 'Inter', sans-serif;
    width: calc(100% - 83px);
    height: 24px;
    padding: 13px 15px;
    margin: 1px;
    background-color: #fff;
    font-size: 18px;
    line-height: 24px;
    color: #000;
    font-weight: 700;
    outline: none;
    border-radius: none;
    border: none;
}

.products-gcgcn-online .search label.lens {
    display: inline-block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 52px;
    height: 52px;
    background-color: white;
}

.products-gcgcn-online .search label.lens img {
    height: 26px;
    padding: 13px;
}

.products-gcgcn-online .property {
    margin: 0 auto;
    box-sizing: border-box;
    width: 100%;
    max-width: 860px;
    padding: 60px 20px;
    text-align: center;
}

.products-gcgcn-online .property h2 {
    font-family: 'Raleway', sans-serif;
}