.main-products .intro {
    box-sizing: border-box;
    width: 100%;
    padding: 40px 20px;
    position: relative;
}

.main-products .intro video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
}

.main-products .intro .contents {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    margin: auto;
    z-index: 500;
}

.main-products .item {
    width: 260px;
    height: 300px;
    margin: 10px;
    display: inline-block;
}

.main-products .item .picture {
    text-align: center;
    margin-top: 20px;
}

.main-products .item .picture img {
    width: 180px;
    height: 180px;
}

.main-products .item .name {
    font-family: 'Oxanium', cursive;
    text-align: center;
    color: white;
    font-size: 2rem;
    margin-top: 40px;
}

.main-products .product {
    width: 100%;
    box-sizing: border-box;
    width: 100%;
    padding: 40px 20px;
}

.main-products .product {
    background-color: #f6f6f6;
    color: #455159;
}

.main-products .product.dark {
    background-color: #455159;
    color: #f6f6f6;
}

.main-products .product .title {
    width: 100%;
    padding: 40px 0;
    text-align: center;
}

.main-products .product .title h1 {
    font-family: 'Oxanium', cursive;
    font-size: 2rem;
    padding-bottom: 20px;
    display: inline;
    margin: 0;
    border-bottom: 4px solid #D32430;
    color: inherit;
}

.main-products .product .body {
    text-align: center;
}


.main-products .product .body h2 {
    font-family: 'Noto Sans KR', sans-serif;
    font-size: 1.5rem;
    letter-spacing: 0.15rem;
}

.main-products .product .body .titleimage {
    margin: 50px 0;
}

.main-products #GCgcn.product .body .titleimage {
    margin: 20px 0;
}

.main-products .product .body .titleimage img {
    width: calc(100% - 60px);
    max-width: 700px;
    margin: 0 30px;
    image-rendering: crisp-edges;
}

.main-products .product .body .links {
    margin: 30px 0;
}

.main-products .product .body .links .button {
    display: inline-block;
    padding: 10px 15px;
    margin: 5px 10px;
    font-family: 'Inter', sans-serif;
    text-decoration: none;
    font-size: 1.15rem;
    color: #D32430;
    border-radius: 1px;
}
.main-products .product .body .links .button:hover {
    background-color: rgba(0, 0, 0, 0.1);
}

@media screen and (max-width: 859px) {

    .main-products .intro { 
        display: none;
    }

}