.products-gcgcn-online-property {
    width: 100%;
    height: 100%;
}

.products-gcgcn-online-property table {
    width: 100%;
    height: 100%;
    border-collapse: collapse;
    background-color: #FAFAFA;
}

.products-gcgcn-online-property table td,
.products-gcgcn-online-property table th {
    border: 1px solid #AAA;
    font-family: 'Inter', sans-serif;
    padding: 10px 20px;
}

.products-gcgcn-online-property table th {
    width: 70px;
}

.products-gcgcn-online-property table td {
    text-align: right;
}

.products-gcgcn-online-property table td.image-body {
    width: 200px;
    text-align: center;
}

.products-gcgcn-online-property table td.image-body img {
    width: 200px;
    height: 200px;
}

@media screen and (max-width: 859px) {

    .products-gcgcn-online-property .imageSVG {
        width: 200px;
        height: 200px;
        margin-bottom: 60px;
        border: 1px solid #AAA;
    }
    
    .products-gcgcn-online-property table td,
    .products-gcgcn-online-property table th {
        padding: 10px 10px;
    }

    .products-gcgcn-online-property table th {
        width: 50px;
    }

}

