
.main-home, .carousel-root {
    box-sizing: border-box;
    height: 100%;
}

.main-home .carousel-slider {
    box-sizing: border-box;
    height: calc(100% - 120px);
}

.main-home .slider-wrapper, .slider-wrapper > ul, .slider-wrapper > ul > li {
    box-sizing: border-box;
    height: 100%;
}

.main-home .section {
    height: 100%;
    background-color: #3c3c3c;
    display: flex;
    justify-content: center;
    align-items: center;
}

.main-home .section .article {
    box-sizing: border-box;
    padding: 40px;
    margin: 0 auto;
}

.main-home .section .article .description {
    display: inline-block;
    width: 600px;
    text-align: left;
    vertical-align: middle;
    color: white;
}

.main-home .section .article .description h1 {
    font-family: 'Oxanium', cursive;
    font-size: 3.25rem;
    letter-spacing: 0.15rem;
    margin: 20px 0;
}

.main-home .section .article .description h2 {
    font-family: 'Oxanium', 'Noto Sans KR', sans-serif;
    font-size: 2.25rem;
    margin: 30px 0;
}

.main-home .section .article .description h2 span {
    color: #e2535d;
}

.main-home .section .article .description p {
    font-family: 'Noto Sans KR', sans-serif;
    font-size: 1.25rem;
    margin: 40px 0;
    color: #bdbdbd;
    line-height: 1.6;
}

.main-home .section .article .description .link {
    display: inline-block;
    font-family: 'Noto Sans KR', sans-serif;
    color: white;
    text-decoration: none;
    padding: 10px 22px 13px 22px;
    border: 2px solid #e2535d;
    border-radius: 3px;
    transition-duration: 150ms;
}

.main-home .section .article .description .link:hover {
    background-color: #e2535d;
}

.main-home .section .article .image {
    display: inline-block;
    width: calc(100% - 600px);
    vertical-align: middle;
}

.main-home .section .article .image img {
    width: 100%;
    max-width: 400px;
}

.main-home .carousel .carousel-status,
.main-home .carousel .control-arrow,
.main-home .carousel .control-dots {
    display: none;
}

.main-home .carousel .thumbs-wrapper {
    margin: 0;
    height: 120px;
    border-width: 0 2px;
    border-style: solid;
    border-color: #3c3c3c;
}

.main-home .carousel .thumbs-wrapper .thumbs {
    margin: 0;
    padding: 0;
    height: 120px;
}

.main-home .carousel .thumb {
    margin: 0;
    width: 100%;
    height: 120px;
    border-width: 4px 2px;
    border-style: solid;
    border-color: #3c3c3c;
    color: white;
    cursor: pointer;
    background-color: #606060;
}

.main-home .carousel .thumb.selected {
    border-width: 4px 2px;
    border-style: solid;
    border-color: #3c3c3c;
    background-color: #484848;
}

.main-home .carousel .thumb:hover {
    border-width: 4px 2px;
    border-style: solid;
    border-color: #3c3c3c;
    color: #e2535d;
}

.main-home .carousel .thumb .thumb-section {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    transition-duration: 150ms;
}

.main-home .carousel .thumb .thumb-section .thumb-article {
    font-family: 'Oxanium', cursive;
    font-size: 1.3rem;
}

.main-home .carousel .thumb .thumb-section .thumb-logo img {
    width: 60px;
    height: 60px;
}

@media screen and (max-width: 859px) {

    .main-home .section .article {
        width: 100%;
        padding: 40px;
    }

    .main-home .section .article .description {
        width: 100%;
    }

    .main-home .section .article .image {
        display: none;
    }

    .main-home .carousel .thumb .thumb-section .thumb-logo { 
        display: none;
    }

}